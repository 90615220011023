import React, { PureComponent } from "react";
import "../../assets/css/product.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Row } from "reactstrap";
import Facebook from "../../assets/images/icons/facebook.svg";
import Whatsapp from "../../assets/images/icons/whatsapp.svg";
import ShowPriceMarker from "../../components/leaflet/markers/ShowPriceMarker";
import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import { openInteractivityModal } from "../../redux/modal/action";
import { isIOS } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import TitleModal from "../modal/modalbody/components/Title.modal";
import GalleryModal from "../modal/modalbody/components/Gallery.modal";
import Price from "../modal/modalbody/components/Price.modal";
import "../../assets/css/categorystatic.css";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TagsModal from "../modal/modalbody/components/Tags.modal";

const productUrl = serverResponse.config.shareFlyerURL;

class Product extends PureComponent {
  state = {
    itemCarousel: 0,
    varietySelected:
      this.props.info &&
      this.props.info.varieties &&
      this.props.info.varieties !== undefined
        ? this.props.info.varieties[0]
        : "",
    saveTooltipOpen: false,
    tooltipPricesOpen: false,
    openModal: false,
    showQuantityButtons: false,
    selectedVarietyQuantity: 0,
    quantity: this.props.info.quantity_step ? this.props.info.quantity_step : 1,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.info.varieties !== state.varietySelected) {
      return {
        varietySelected: props.info.varieties ? props.info.varieties[0] : "",
        quantity: props.info.quantity_step ? props.info.quantity_step : 1,
      };
    }
    return null;
  }

  componentDidMount() {
    this.updateProductDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.groceryList.list !== prevProps.groceryList.list ||
      this.props.info.item_id !== prevProps.info.item_id ||
      this.props.info.varieties !== prevProps.info.varieties ||
      this.state.varietySelected !== prevState.varietySelected
    ) {
      this.updateProductDetails();
    }
  }

  updateProductDetails() {
    const product = this.props.groceryList.list.find(
      (product) =>
        product.item_id === this.props.info.item_id &&
        (!this.props.info.varieties.length ||
          product.variety === this.state.varietySelected)
    );

    if (product) {
      this.setState({
        selectedVarietyQuantity: product.quantity,
        showQuantityButtons: true,
      });
    } else {
      this.setState({
        selectedVarietyQuantity: 0,
        showQuantityButtons: false,
      });
    }
  }

  navMarkers = (newMarker) => {
    var element = document.getElementById(
      newMarker + "-" + this.props.info.item_id
    );
    element.click();
  };

  incrementQty = () => {
    const product = this.props.groceryList.list.find(
      (product) =>
        product.item_id === this.props.info.item_id &&
        (product.variety === this.state.varietySelected ||
          (!product.variety && !this.state.varietySelected))
    );

    if (product) {
      const newQuantity =
        product.quantity +
        (this.props.info.quantity_step ? this.props.info.quantity_step : 1);

      const updatedItem = {
        ...product,
        quantity: newQuantity,
      };

      this.props.changeQuantity({
        quantity: newQuantity,
        item: updatedItem,
      });
    }
  };

  decrementQty = () => {
    const product = this.props.groceryList.list.find(
      (product) =>
        product.item_id === this.props.info.item_id &&
        (product.variety === this.state.varietySelected ||
          (!product.variety && !this.state.varietySelected))
    );

    if (product) {
      let newQuantity = product.quantity;
      if (this.props.info.quantity_step) {
        if (newQuantity > this.props.info.quantity_step) {
          newQuantity -= this.props.info.quantity_step;
        } else {
          this.deleteProduct();
        }
      } else {
        if (newQuantity > 1) {
          newQuantity -= 1;
        }
      }

      const updatedItem = {
        ...product,
        quantity: newQuantity,
      };

      this.props.changeQuantity({
        quantity: newQuantity,
        item: updatedItem,
      });
    }
  };

  deleteProduct = () => {
    const product = this.props.groceryList.list.find(
      (product) =>
        product.item_id === this.props.info.item_id &&
        (product.variety === this.state.varietySelected ||
          (!product.variety && !this.state.varietySelected))
    );
    if (product) {
      this.props.deleteProduct({
        item: product,
      });
    }
  };

  shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(this.props.info.item_id)
    );
    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  toggle = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };
  shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(this.props.info.item_id)
    );
    let page_url =
      productUrl + "/share/product.php?c=" + this.props.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  handleAddProduct = () => {
    let obj = Object.assign({}, this.props.info);
    obj.quantity = this.state.quantity;
    obj.done = false;
    obj.variety =
      !this.state.varietySelected || this.state.varietySelected === undefined
        ? ""
        : this.state.varietySelected;
    this.props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );

    if (serverResponse.config.ga_active) {
      sendAnalyticsEvent(
        gaEventLabel.add_to_grocery_list,
        String(this.props.info.item_id)
      );
      sendAnalyticsEvent(
        gaEventLabel.open_product,
        String(this.props.info.item_id)
      );
    }
  };

  handleChangeVariety = (e) => {
    this.setState({
      varietySelected: e.target.value,
    });
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  toggleTooltipPricesOpen = () => {
    this.setState({ tooltipPricesOpen: !this.state.tooltipPricesOpen });
  };

  setVarietySelected = () => {
    this.setState({ varietySelected: "" });
  };

  render() {
    const { info } = this.props;
    // let markers = this.props.info.markers
    //   .filter((el) => el.type === "plus")
    //   .map((element, index) => {
    //     return (
    //       <Marker
    //         from_product={true}
    //         key={index}
    //         info={this.props.info}
    //         markerInfo={element}
    //         leafletSwipe={this.props.leafletSwipe}
    //         dynamic_price={this.props.dynamic_price}
    //         dynamic_info={this.props.dynamic_info}
    //         show_dynamic_price={this.props.show_dynamic_price}
    //         type={element.type}
    //       />
    //     );
    //   });

    // const stock = {
    //   y: {
    //     text: "Disponibile",
    //     color: "green",
    //     icon: <i className="fas fa-check"></i>
    //   },
    //   n: {
    //     text:
    //       "Non disponibile" +
    //       (this.props.info.tdc !== null
    //         ? " - arrivo tra " + this.props.info.tdc + " giorni"
    //         : ""),
    //     color: "red",
    //     icon: <i className="fas fa-times"></i>
    //   },
    //   c: {
    //     text: this.props.info.available_from,
    //     color: "var(--secondary-color)",
    //     icon: <i className="far fa-clock"></i>
    //   }
    // };

    // var disp = stock[this.props.info.stock];

    // eslint-disable-next-line no-unused-vars
    let hidden_price_marker = [];
    if (this.props.info.hidden_price) {
      hidden_price_marker = this.props.info.markers.map((element, key) => {
        switch (element.type) {
          case "play": {
            return (
              <ShowPriceMarker
                showButton={true}
                key={key + 10}
                info={info}
                markerInfo={element}
                leafletSwipe={() => {}}
                navMarkers={this.navMarkers}
                setSwipe={() => {}}
              />
            );
          }
          default:
            return null;
        }
      });
    }

    // const varieties = (
    //   <Row
    //     style={{
    //       minHeight: "0px",
    //       // marginBottom: "15px",
    //       opacity: this.props.info.varieties.length > 0 ? 1 : 0
    //     }}
    //   >
    //     <Col md="12">
    //       {/* <Label for="variety">Scegli:</Label>*/}
    //       <InputGroup className="p-0">
    //         {/*    <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>*/}
    //         <InputGroupAddon addonType="prepend"></InputGroupAddon>
    //         <Input
    //           style={{ borderRadius: "6px" }}
    //           type="select"
    //           name="select"
    //           id="variety"
    //           value={this.state.varietySelected}
    //           onChange={this.handleChangeVariety}
    //         >
    //           {this.props.info.varieties.map((element, key) => {
    //             return (
    //               <option key={key} value={element}>
    //                 {element}
    //               </option>
    //             );
    //           })}
    //         </Input>
    //       </InputGroup>
    //     </Col>
    //   </Row>
    // );

    // eslint-disable-next-line no-unused-vars
    let brandImg = "";
    if (
      serverResponse.config.brandImage !== "" &&
      this.props.info.pam === true
    ) {
      brandImg = (
        <img
          style={{ width: "30%" }}
          src={process.env.PUBLIC_URL + serverResponse.config.brandImage}
          alt="img"
        />
      );
    }

    // eslint-disable-next-line no-unused-vars
    let cardImg = "";
    if (
      serverResponse.config.underPriceImage !== "" &&
      serverResponse.config.underPriceImage !== null &&
      this.props.info.underpriced_product === true
    ) {
      cardImg = (
        <img
          style={{ width: "50%", marginTop: "10px" }}
          src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage}
          alt="img"
        />
      );
    }

    // eslint-disable-next-line no-unused-vars
    let h6Class = "";
    // eslint-disable-next-line no-unused-vars
    let isLineThrough = false;
    if (Boolean(serverResponse.config.line_through) === true) {
      h6Class = "barrato";
      isLineThrough = true;
    }

    return (
      <>
        <div className="row w-100 m-0 p-0 h-100">
          <div className="col-md-12 p-0">
            <div className="product-item-block d-flex flex-column">
              <span
                onClick={() => {
                  this.props.openModal(
                    { ...this.props, type: "plus" },
                    info.type
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <GalleryModal
                  customClassContainer={"m-0"}
                  customClassCol={"p-1"}
                  maxWidth={"220px"}
                  gallery={[info.images ? info.images[0] : []]}
                  arrows={false}
                />
              </span>
              <div className="d-flex justify-content-between flex-column h-100">
                <div className="product-item-Name">
                  {/* Testi */}
                  <TitleModal
                    lineHeight="1.2rem"
                    fontSize="18px"
                    title={info.field1}
                  />
                  <p
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "14px",
                      // textTransform: "uppercase"
                    }}
                  >
                    {info.field2} {info.field3} {info.field4}
                  </p>
                </div>

                <div>
                  {/* <div>{varieties}</div> */}
                  <div className="product-price-modal">
                    <TagsModal tag={info.tags} />
                    <div className="categoriesPrice">
                      <Price getPrice={false} left={0} col={false} {...info} />
                    </div>
                  </div>
                  {!this.props.info.varieties.length > 0 ? (
                    !this.state.showQuantityButtons ? (
                      !serverResponse?.config.hide_grocery_list ? (
                        <button className="btn" onClick={this.handleAddProduct}>
                          {language[defaultLanguage].aggiungi}
                        </button>
                      ) : (
                        <></>
                      )
                    ) : (
                      <div className="queProductBox">
                        {/* <button
                          className="btn btn-reset"
                          onClick={this.deleteProduct}
                        >
                          elimina
                        </button> */}
                        <div
                          className="select-quantity-wrap "
                          // style={{ width: "172px" }}
                        >
                          <div
                            className="increase-quantity"
                            onClick={this.decrementQty}
                          >
                            <FontAwesomeIcon className="icon" icon={faMinus} />
                          </div>
                          <div className="show-quantity">
                            {this.state.selectedVarietyQuantity}{" "}
                            {this.props.info.grocery_label}
                          </div>
                          <div
                            className="decrease-quantity"
                            onClick={this.incrementQty}
                          >
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <button
                      className="btn"
                      onClick={() => {
                        this.props.openModal(
                          { ...this.props, type: "plus" },
                          info.type
                        );
                      }}
                    >
                      {language[defaultLanguage].apri}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-1 p-0"></div> */}
        </div>

        {/* {!info.focus &&
          !info.one_and_one_gratis &&
          !serverResponse.config.send_grocery_list_to_market &&
          !serverResponse.config.hide_grocery_list && (
            <div className=" m-0 row">
              <div className="col-12 p-0">
                <div className="row">
                  <AddCartModal
                    text_value="AGGIUNGI"
                    // colCustom={"col-12"}

                    justifyContent={"space-between"}
                    setVarietySelected={() => {}}
                    varietySelected={this.state.varietySelected}
                    info={info}
                    price={true}
                  />
                </div>
              </div>
            </div>
          )} */}

        <div>
          <Modal centered isOpen={this.state.openModal} toggle={this.toggle}>
            <ModalHeader>{language[defaultLanguage].condividi_con}</ModalHeader>
            <ModalBody>
              <Row className="custom-share-container">
                {!serverResponse.config.hide_share_product && (
                  <img
                    src={Facebook}
                    className="share-icon"
                    alt="facebook"
                    onClick={() => this.shareOnFacebook()}
                  />
                )}
                {!serverResponse.config.hide_share_product && (
                  <img
                    src={Whatsapp}
                    className="share-icon"
                    alt="whatsapp"
                    onClick={() => this.shareOnWhatsapp()}
                  />
                )}
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    changeQuantity: (newQuantity) => {
      dispatch(actions.changeQuantity(newQuantity));
    },
    openModal: (obj, code) => {
      dispatch(openInteractivityModal(obj, code));
    },
    deleteProduct: (deleted) => {
      dispatch(actions.deleteProduct(deleted));
    },
  };
}

export default connect(null, mapDispatchToProps)(Product);
