import React, { useEffect, useState } from "react";
import { serverResponse } from "../../../../util/fakeServer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { strings } from "../../../../util/data/strings";
import { connect } from "react-redux";
import actions from "../../../../redux/groceryList/action";
import { isModalOpen } from "../../../../redux/carousel/action";
import { NotificationManager } from "react-notifications";
import { language } from "../../../../languageProvider/language/language";
import {
  defaultLanguage,
  sendAnalyticsEvent,
} from "../../../../helper/utility";
import { gaEventLabel } from "../../../../settings/gaConfig";
import useCurrentWidth from "../../../../hooks/widthHook";
import { BREAKPOINT } from "../../../../helper/constant";
import ManagerFactory from "../../../../managers/ManagerFactory";
import { closeInteractivityModal } from "../../../../redux/modal/action";
import { FileIcon } from "../../../../assets/icons";

const manager = ManagerFactory.getManager();
const CartModal = (props) => {
  const [quantity] = useState(
    props.info.quantity_step ? props.info.quantity_step : 1
  );
  const [showQuantityButtons, setShowQuantityButtons] = useState(false);
  const [selectedVarietyQuantity, setSelectedVarietyQuantity] = useState(0);

  const currentWidth = useCurrentWidth();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    props.setVarietySelected(
      props.info.varieties.length > 0 ? props.info.varieties[0] : ""
    );
  }, [props.info]);

  useEffect(() => {
    const product = props.groceryList.list.find(
      (product) =>
        product.item_id === props.info.item_id &&
        (!props.info.varieties.length ||
          product.variety === props.varietySelected)
    );

    if (product) {
      setSelectedVarietyQuantity(product.quantity);
      setShowQuantityButtons(true);
    } else {
      setSelectedVarietyQuantity(0);
      setShowQuantityButtons(false);
    }
  }, [
    props.groceryList.list,
    props.info.item_id,
    props.info.varieties,
    props.varietySelected,
  ]);

  const incrementQty = () => {
    const product = props.groceryList.list.find(
      (product) =>
        product.item_id === props.info.item_id &&
        product.variety === props.varietySelected
    );

    if (product) {
      const newQuantity =
        product.quantity +
        (props.info.quantity_step ? props.info.quantity_step : 1);

      const updatedItem = {
        ...product,
        quantity: newQuantity,
      };

      props.changeQuantity({
        quantity: newQuantity,
        item: updatedItem,
      });
    }
  };

  const decrementQty = () => {
    const product = props.groceryList.list.find(
      (product) =>
        product.item_id === props.info.item_id &&
        product.variety === props.varietySelected
    );

    if (product) {
      let newQuantity = product.quantity;
      if (props.info.quantity_step) {
        if (newQuantity > props.info.quantity_step) {
          newQuantity -= props.info.quantity_step;
        } else {
          deleteProduct();
        }
      } else {
        if (newQuantity > 1) {
          newQuantity -= 1;
        }
      }

      const updatedItem = {
        ...product,
        quantity: newQuantity,
      };

      props.changeQuantity({
        quantity: newQuantity,
        item: updatedItem,
      });
    }
  };

  const deleteProduct = () => {
    const product = props.groceryList.list.find(
      (product) =>
        product.item_id === props.info.item_id &&
        (product.variety === props.varietySelected ||
          (!product.variety && !props.varietySelected))
    );
    if (product) {
      props.deleteProduct({
        item: product,
      });
    }
  };

  const toggleSaveTooltip = () => {
    setIsOpen(!isOpen);
  };

  const handleAddProduct = () => {
    let obj = Object.assign({}, props.info);
    obj.quantity = quantity;
    obj.done = false;
    obj.variety = props.varietySelected;

    props.addProduct(obj);
    NotificationManager.success(
      language[defaultLanguage].controlla_la_tua_lista_della_spesa,
      language[defaultLanguage].prodotto_aggiunto,
      800
    );
    if (document.getElementById("selected-" + props.info.id) != null)
      document.getElementById("selected-" + props.info.item_id).style[
        "display"
      ] = "inline";
    sendAnalyticsEvent(
      gaEventLabel.add_to_grocery_list,
      String(props.info.item_id)
    );

    if (manager.closeModalAfterAddProd()) {
      props.closeInteractivityModal();
    }
  };

  if (serverResponse.config.hide_grocery_list) return <></>;

  return (
    <>
      <div
        style={{ fontFamily: "var(--font-family)", ...props.additionalStyle }}
        className={`${
          props.colCustom || "col-12"
        } d-flex justify-content-around align-items-center `}
      >
        {!props.info.one_and_one_gratis && (
          <>
            {/* <FontAwesomeIcon
              className="change-product-quantity-icon iconStyleModal iconL "
              icon={faMinus}
              onClick={decrementQty}
            />
            <h6 style={{ textTransform: "capitalize" }}>
              {quantity} {props.info.grocery_label}
            </h6>

            <FontAwesomeIcon
              className="change-product-quantity-icon iconStyleModal iconR"
              icon={faPlus}
              onClick={incrementQty}
            /> */}

            {/* New UI */}
            {/* <div className="select-quantity-wrap mb-2">
              <div className="increase-quantity" onClick={decrementQty}>
                <FontAwesomeIcon className="icon" icon={faMinus} />
              </div>
              <div className="show-quantity">
                {quantity} {props.info.grocery_label}
              </div>
              <div className="decrease-quantity" onClick={incrementQty}>
                <FontAwesomeIcon className="icon" icon={faPlus} />
              </div>
            </div> */}
          </>
        )}

        {props.componentPrice}
      </div>
      {!props.info.one_and_one_gratis && (
        <>
          {!showQuantityButtons ? (
            <button
              style={{
                borderRadius: "6px",
                marginLeft: currentWidth >= BREAKPOINT.xs ? "15px" : "0",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "18px",
                height: "36px",
                fontFamily: "Rubik",
                textTransform: "none !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "172px",
              }}
              // maxWidth: currentWidth >= BREAKPOINT.xs ? "200px" : "unset",
              disabled={
                props.info.stock !== "y" &&
                props.info.stock !== null &&
                serverResponse.config.manager_stock
              }
              id={"btnSave_" + props.info.item_id}
              className="btn btn-green mx-0 px-4 add-cart-modal-btn  text-center py-1 w-100"
              onClick={handleAddProduct}
            >
              {language[defaultLanguage].aggiungi_alla_lista}{" "}
              <FileIcon style={{ marginLeft: "10px" }} />
            </button>
          ) : (
            <div
              className="select-quantity-wrap mb-2"
              style={{ width: "172px" }}
            >
              <div className="increase-quantity" onClick={decrementQty}>
                <FontAwesomeIcon className="icon" icon={faMinus} />
              </div>
              <div className="show-quantity">
                {selectedVarietyQuantity} {props.info.grocery_label}
              </div>
              <div className="decrease-quantity" onClick={incrementQty}>
                <FontAwesomeIcon className="icon" icon={faPlus} />
              </div>
            </div>
          )}

          {props.info.stock === "t" && (
            <Tooltip
              placement="top"
              isOpen={this.state.saveTooltipOpen}
              target={"btnSave_" + props.info.item_id}
              toggle={toggleSaveTooltip}
            >
              {strings.saveBtnTooltip}
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    changeQuantity: (newQuantity) => {
      dispatch(actions.changeQuantity(newQuantity));
    },
    isModalOpen: (bool) => {
      dispatch(isModalOpen(bool));
    },
    closeInteractivityModal: () => {
      dispatch(closeInteractivityModal());
    },
    deleteProduct: (deleted) => {
      dispatch(actions.deleteProduct(deleted));
    },
  };
};

export default connect(null, mapDispatchToProps)(CartModal);
