import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { isFirefox } from "react-device-detect";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
// import { styles } from "./ShareGroceryListBtn.style";
import { NotificationManager } from "react-notifications";
import { defaultLanguage, getCustomActions } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
// import { CondividiNew } from "../../assets/images/icone";
import ForwardIcon from "../../assets/icons/forward.icon";
import { styles } from "../../components/common/shareModal.style";

class ShareGroceryList extends Component {
  state = {
    modal: false,
    idParam: 0,
    copyAlert: false,
  };

  openReactNativeShare = () => {
    let link =
      serverResponse.config.serverPath + "myGroceryList/" + this.state.idParam;
    let title = language[defaultLanguage].visualizza_lista_spesa;
    var str = title + "    " + link;
    var obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  pause = (timeoutMsec) =>
    new Promise((resolve) => setTimeout(resolve, timeoutMsec));

  exportGroceryList = (type = "") => {
    var tmpProducts = this.props.groceryList.list;

    tmpProducts.forEach((element) => {
      element.image_file = element.images[0]
        ? element.images[0].image_file
        : "";
    });

    var idParam = Math.random().toString(36).substr(2, 16);
    this.setState({
      idParam: idParam,
    });

    var exportObj = {
      id_param_url: this.state.idParam,
      id_campagna: this.props.groceryList.idCampaign,
      prodotti: this.props.groceryList.list,
      flyer_url: serverResponse.config.shareFlyerURL,
      pid: this.props.auth.user ? this.props.auth.user.id : null,
    };

    getCustomActions().exportList(exportObj);

    if (type === "email") {
      let target = "_self";
      if (isFirefox) target = "_blank";
      window.open(
        `mailto:?body=${getCustomActions().getShareListLink(
          this.state.idParam
        )}`,
        target
      );
    }
  };

  componentDidMount() {
    var idParam = Math.random().toString(36).substr(2, 16);

    this.setState({
      idParam: idParam,
    });
  }

  render() {
    let link = getCustomActions().getShareListLink(this.state.idParam);
    let title = language[defaultLanguage].visualizza_lista_spesa;

    let wa1 = "inline";
    let wa2 = "none";

    const copyToClip = () => {
      navigator.clipboard
        .writeText(link)
        .then(() =>
          NotificationManager.success(
            language[defaultLanguage].elemento_copiato,
            language[defaultLanguage].link_copiato_negli_appunti,
            1000
          )
        )
        .catch((err) => {
          NotificationManager.error(
            language[defaultLanguage].errore_nella_copia,
            language[defaultLanguage].link_non_copiato,
            1000
          );
        });
    };

    if (window.ReactNativeWebView !== undefined) {
      wa1 = "none";
      wa2 = "inline";
    }

    // const shareButtonStyle =
    //   this.props.groceryList.list.length === 0
    //     ? {
    //         color: "white",
    //         fontWeight: "bold",
    //         display: "flex",
    //         justifyContent: "space-between",
    //         background: "grey",
    //         width: "170px",
    //       }
    //     : {
    //         color: "white",
    //         // padding: "10px",
    //         fontWeight: "bold",
    //         display: "flex",
    //         justifyContent: "space-between",
    //         cursor: "pointer",
    //         width: "170px",
    //       };

    // let share_label = serverResponse.config.send_grocery_list_to_market
    //   ? language[defaultLanguage].condividi
    //   : language[defaultLanguage].condividi_lista;

    return (
      <div
        // style={shareButtonStyle}
        onClick={() => {
          if (this.props.groceryList.list.length === 0) {
            return;
          } else {
            this.openModal();
          }
        }}
      >
        {/* style={styles.shareBtn(serverResponse.config.secondary_color)} */}
        <div className="shareButton d-flex align-items-center justify-content-center w-100 cursor-pointer">
          <span className="forward-text d-none">Condividi</span>
          <ForwardIcon />
          {/* <CondividiNew style={{ width: "35px" }} />

          <span>{share_label}</span> */}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.closeModal()}
          centered={true}
          style={{ height: "200px" }}
          className="modal_share_custom"
        >
          <ModalHeader toggle={() => this.closeModal()}>
            {language[defaultLanguage].condividi_con}...
          </ModalHeader>
          <ModalBody style={{ height: "auto" }}>
            <div
              className="custom-share-container"
              style={{ alignItems: "center", display: "flex" }}
            >
              <FacebookShareButton
                className="facebookShareButton shareIcon"
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <FacebookIcon size={32} bgStyle={{ fill: "black" }} />
              </FacebookShareButton>

              <TwitterShareButton
                className="twitterShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <TwitterIcon size={32} bgStyle={{ fill: "black" }} />
              </TwitterShareButton>

              <TelegramShareButton
                className="telegramShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <TelegramIcon size={32} bgStyle={{ fill: "black" }} />
              </TelegramShareButton>

              <WhatsappShareButton
                className="whatsappShareIcon shareIcon"
                title={title}
                url={link}
                style={{ display: wa1 }}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <WhatsappIcon size={32} bgStyle={{ fill: "black" }} />
              </WhatsappShareButton>

              <span
                style={{ display: wa2 }}
                onClick={() => this.openReactNativeShare()}
              >
                <WhatsappIcon size={32} bgStyle={{ fill: "black" }} />
              </span>

              <div
                onClick={(e) => {
                  e.preventDefault();
                  this.exportGroceryList("email");
                }}
                aria-label="email"
                role="button"
                tabIndex="0"
                className="SocialMediaShareButton SocialMediaShareButton--email emailShareIcon shareIcon"
              >
                <EmailIcon size={32} bgStyle={{ fill: "black" }} />
              </div>
              {/* <EmailShareButton
                title="Invia Email"
                iconFillColor="red"
                className="emailShareIcon shareIcon"
                url={link}
                bgStyle={{ fill: "red" }}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <EmailIcon
                  title="Invia Email"
                  bgStyle={{ fill: "red" }}
                  size={32}
                  round={true}
                />
              </EmailShareButton> */}

              <div
                className="copyButton shareIcon"
                data-tooltip= {language[defaultLanguage].copia_link_lista}
                onClick={() => {
                  this.exportGroceryList();
                  copyToClip();
                }}
                style={styles.copyButton}
              >
                <FontAwesomeIcon
                  style={{ margin: 0 }}
                  color="white"
                  size="sm"
                  icon={faCopy}
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList,
    auth: state.authJWT,
  };
};

export default connect(mapStateToProps, null)(ShareGroceryList);
